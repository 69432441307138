import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
  Label
} from 'recharts';
import '../styles/AdminDashboard.css';
import { formatCurrency } from '../utils/format';

function AdminDashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const auth = getAuth();
  const [stats, setStats] = useState(null);
  const [userFlowData, setUserFlowData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('overview');
  const [promoCodes, setPromoCodes] = useState([]);
  const [newPromoCode, setNewPromoCode] = useState({
    code: '',
    discount_percentage: '',
    max_uses: 1,
    expires_at: ''
  });
  const [affiliates, setAffiliates] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Check if user is admin
    const checkAdmin = async () => {
      try {
      const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/check`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          navigate('/');
        }
      } catch (error) {
        console.error('Admin check failed:', error);
        navigate('/');
      }
    };

    checkAdmin();
  }, [user, navigate]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/stats`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, [user]);

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/promo-codes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        setPromoCodes(data);
      } catch (error) {
        console.error('Failed to fetch promo codes:', error);
      }
    };

    if (selectedTab === 'promo-codes') {
      fetchPromoCodes();
    }
  }, [user, selectedTab]);

  useEffect(() => {
    const fetchAffiliateData = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/affiliates`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        setAffiliates(data.affiliates);
        setPendingPayments(data.pendingPayments);
      } catch (error) {
        console.error('Failed to fetch affiliate data:', error);
      }
    };

    if (selectedTab === 'affiliates') {
      fetchAffiliateData();
    }
  }, [selectedTab]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/users`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    if (selectedTab === 'users') {
      fetchUsers();
    }
  }, [selectedTab]);

  const handleRemoveGallery = async (galleryId) => {
    if (!confirm('Are you sure you want to remove this gallery?')) return;
    
    try {
      const token = await auth.currentUser.getIdToken();
      await fetch(`${import.meta.env.VITE_API_URL}/api/admin/galleries/${galleryId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Refresh stats after removal by triggering the useEffect
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/stats`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Failed to remove gallery:', error);
    }
  };

  const handleCreatePromoCode = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/promo-codes`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newPromoCode)
      });

      if (response.ok) {
        // Reset form and refresh promo codes
        setNewPromoCode({
          code: '',
          discount_percentage: '',
          max_uses: 1,
          expires_at: ''
        });
        const data = await response.json();
        setPromoCodes([...promoCodes, data]);
      }
    } catch (error) {
      console.error('Failed to create promo code:', error);
    }
  };

  const handleDeactivatePromoCode = async (promoId) => {
    if (!confirm('Are you sure you want to deactivate this promo code?')) return;

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      await fetch(`${import.meta.env.VITE_API_URL}/api/admin/promo-codes/${promoId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Update the local state to reflect the change
      setPromoCodes(promoCodes.map(code => 
        code.id === promoId ? { ...code, is_active: false } : code
      ));
    } catch (error) {
      console.error('Failed to deactivate promo code:', error);
    }
  };

  const handleMarkAsPaid = async (paymentId) => {
    if (!confirm('Are you sure you want to mark this payment as paid?')) return;
    
    try {
      const token = await auth.currentUser.getIdToken();
      await fetch(`${import.meta.env.VITE_API_URL}/api/admin/affiliates/payments/${paymentId}/paid`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Refresh affiliate data
      setPendingPayments(pendingPayments.filter(p => p.id !== paymentId));
    } catch (error) {
      console.error('Failed to mark payment as paid:', error);
    }
  };

  const handleToggleAffiliateStatus = async (affiliateId) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/affiliates/${affiliateId}/toggle`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const updatedAffiliate = await response.json();
      
      // Update affiliates list
      setAffiliates(affiliates.map(aff => 
        aff.id === affiliateId ? { ...aff, is_active: !aff.is_active } : aff
      ));
    } catch (error) {
      console.error('Failed to toggle affiliate status:', error);
    }
  };

  const handleToggleAffiliateEligibility = async (userId) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/users/${userId}/toggle-affiliate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        // Update users list
        setUsers(users.map(user => 
          user.id === userId 
            ? { ...user, can_be_affiliate: !user.can_be_affiliate }
            : user
        ));
      }
    } catch (error) {
      console.error('Failed to toggle affiliate eligibility:', error);
    }
  };

  if (!stats) {
    return (
      <div className="admin-dashboard">
        <div className="loading-spinner">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="admin-dashboard">
      <div className="admin-header">
        <button 
          onClick={() => navigate('/')} 
          className="back-to-home"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          Back to Home
        </button>
      </div>

      <nav className="admin-nav">
        <button 
          className={selectedTab === 'overview' ? 'active' : ''} 
          onClick={() => setSelectedTab('overview')}
        >
          Overview
        </button>
        <button 
          className={selectedTab === 'users' ? 'active' : ''} 
          onClick={() => setSelectedTab('users')}
        >
          Users
        </button>
        <button 
          className={selectedTab === 'galleries' ? 'active' : ''} 
          onClick={() => setSelectedTab('galleries')}
        >
          Galleries
        </button>
        <button 
          className={selectedTab === 'analytics' ? 'active' : ''} 
          onClick={() => setSelectedTab('analytics')}
        >
          Analytics
        </button>
        <button 
          className={selectedTab === 'promo-codes' ? 'active' : ''} 
          onClick={() => setSelectedTab('promo-codes')}
        >
          Promo Codes
        </button>
        <button 
          className={selectedTab === 'affiliates' ? 'active' : ''} 
          onClick={() => setSelectedTab('affiliates')}
        >
          Affiliates
        </button>
      </nav>

      <div className="admin-content">
        {selectedTab === 'overview' && (
          <div className="overview-stats">
            <div className="stat-card">
              <h3>Total Users</h3>
              <p>{stats.totalUsers.toLocaleString()}</p>
            </div>
            <div className="stat-card">
              <h3>Active Galleries</h3>
              <p>{stats.activeGalleries.toLocaleString()}</p>
            </div>
            <div className="stat-card">
              <h3>Active Subscriptions</h3>
              <p>{stats.activeSubscriptions.toLocaleString()}</p>
            </div>
            <div className="stat-card">
              <h3>Total Revenue</h3>
              <p>GHC {stats.totalRevenue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</p>
            </div>
          </div>
        )}

        {selectedTab === 'users' && (
          <div className="user-flow">
            <h3 className="text-xl font-semibold mb-6">User Journey Analysis</h3>
            
            {/* Journey Metrics */}
            <div className="journey-metrics grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="metric-card bg-white p-4 rounded-lg shadow">
                <h4 className="text-gray-600 text-sm font-medium">Conversion Rate</h4>
                <p className="text-2xl font-bold text-blue-600">
                  {stats.userFlow && stats.userFlow.length > 0 ? (
                    `${((stats.userFlow[stats.userFlow.length - 1]?.users / stats.userFlow[0]?.users) * 100 || 0).toFixed(1)}%`
                  ) : '0%'}
                </p>
                <span className="text-sm text-gray-500">Visit to Purchase</span>
              </div>

              <div className="metric-card bg-white p-4 rounded-lg shadow">
                <h4 className="text-gray-600 text-sm font-medium">Critical Drop-off</h4>
                <p className="text-2xl font-bold text-orange-600">
                  {stats.userFlow && stats.userFlow.length > 1 ? (
                    stats.userFlow.reduce((max, point, i, arr) => {
                      if (i === 0) return max;
                      const dropoff = ((arr[i-1].users - point.users) / arr[i-1].users) * 100;
                      return dropoff > max.dropoff ? { step: point.step, dropoff } : max;
                    }, { step: 'None', dropoff: 0 }).step
                  ) : 'No data'}
                </p>
                <span className="text-sm text-gray-500">Highest Drop-off Stage</span>
              </div>

              <div className="metric-card bg-white p-4 rounded-lg shadow">
                <h4 className="text-gray-600 text-sm font-medium">Average Time</h4>
                <p className="text-2xl font-bold text-green-600">
                  {stats.averageCompletionTime ? `${stats.averageCompletionTime} min` : 'N/A'}
                </p>
                <span className="text-sm text-gray-500">Journey Completion</span>
              </div>
            </div>

            {/* Journey Chart */}
            {stats.userFlow && stats.userFlow.length > 0 ? (
              <div className="journey-chart bg-white p-6 rounded-lg shadow">
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart data={stats.userFlow}>
                    <defs>
                      <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#2563eb" stopOpacity={0.1}/>
                        <stop offset="95%" stopColor="#2563eb" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis 
                      dataKey="step" 
                      tick={{ fill: '#666' }}
                    >
                      <Label 
                        value="User Journey Stages" 
                        position="bottom" 
                        style={{ textAnchor: 'middle', fill: '#666', marginTop: '10px' }}
                      />
                    </XAxis>
                    <YAxis tick={{ fill: '#666' }}>
                      <Label 
                        value="Number of Users" 
                        angle={-90} 
                        position="left" 
                        style={{ textAnchor: 'middle', fill: '#666' }}
                      />
                    </YAxis>
                    <Tooltip 
                      formatter={(value) => [value.toLocaleString(), "Users"]}
                      contentStyle={{ 
                        backgroundColor: '#fff', 
                        border: '1px solid #e5e7eb',
                        borderRadius: '6px'
                      }}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="users" 
                      stroke="#2563eb" 
                      fillOpacity={1} 
                      fill="url(#colorUsers)"
                      strokeWidth={2}
                      name="Active Users"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="text-center py-8 bg-white rounded-lg shadow">
                <p className="text-gray-500">No journey data available</p>
              </div>
            )}

            {/* Journey Insights */}
            {stats.userFlow && stats.userFlow.length > 0 && (
              <div className="journey-insights mt-6 bg-white p-6 rounded-lg shadow">
                <h4 className="text-lg font-medium mb-4">Stage-by-Stage Analysis</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {stats.userFlow.map((point, index, arr) => {
                    if (!point) return null;
                    
                    const prevUsers = arr[index - 1]?.users || point.users;
                    const currentUsers = point.users || 0;
                    const dropoff = index > 0 
                      ? ((prevUsers - currentUsers) / prevUsers * 100).toFixed(1) 
                      : 0;
                    
                    return (
                      <div key={point.step} className="insight-card border p-4 rounded">
                        <div className="flex justify-between items-center">
                          <span className="font-medium">{point.step}</span>
                          <span className={`text-sm px-2 py-1 rounded ${
                            Number(dropoff) > 20 
                              ? 'bg-red-100 text-red-800' 
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {dropoff}% Drop-off
                          </span>
                        </div>
                        <p className="text-sm text-gray-600 mt-2">
                          {currentUsers.toLocaleString()} active users
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">User Management</h3>
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b">
                    <th className="pb-2">Name</th>
                    <th className="pb-2">Email</th>
                    <th className="pb-2">Created At</th>
                    <th className="pb-2">Affiliate Status</th>
                    <th className="pb-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id} className="border-b">
                      <td className="py-2">{user.name}</td>
                      <td className="py-2">{user.email}</td>
                      <td className="py-2">
                        {new Date(user.created_at).toLocaleDateString()}
                      </td>
                      <td className="py-2">
                        <span className={`px-2 py-1 rounded text-sm ${
                          user.can_be_affiliate 
                            ? 'bg-blue-100 text-blue-800' 
                            : 'bg-gray-100 text-gray-800'
                        }`}>
                          {user.can_be_affiliate ? 'Can be affiliate' : 'Cannot be affiliate'}
                        </span>
                      </td>
                      <td className="py-2">
                        <button 
                          onClick={() => handleToggleAffiliateEligibility(user.id)}
                          className={`px-3 py-1 rounded ${
                            user.can_be_affiliate
                              ? 'bg-red-500 text-white hover:bg-red-600'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                        >
                          {user.can_be_affiliate ? 'Remove Eligibility' : 'Make Eligible'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {selectedTab === 'galleries' && (
          <div className="galleries-list">
            <h3>Active Galleries</h3>
            <table>
              <thead>
                <tr>
                  <th>Subdomain</th>
                  <th>Owner</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {stats.galleries?.map(gallery => (
                  <tr key={gallery?.id}>
                    <td>
                      <a 
                        href={`https://${gallery?.subdomain || ''}.myphotosgallery.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gallery-link"
                      >
                        {gallery?.subdomain || 'N/A'}
                      </a>
                    </td>
                    <td>{gallery?.owner_email || 'N/A'}</td>
                    <td>{gallery?.created_at ? new Date(gallery.created_at).toLocaleDateString() : 'N/A'}</td>
                    <td>
                      <span className={`status-badge ${(gallery?.status || '').toLowerCase()}`}>
                        {gallery?.status || 'Unknown'}
                      </span>
                    </td>
                    <td>
                      <button 
                        onClick={() => handleRemoveGallery(gallery?.id)}
                        className="btn-danger"
                        disabled={!gallery?.id}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedTab === 'analytics' && (
          <div className="analytics">
            <h3>User Activity</h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={stats.userActivity}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="action" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#2563eb" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}

        {selectedTab === 'promo-codes' && (
          <div className="promo-codes-section">
            <div className="create-promo-code">
              <h3>Create New Promo Code</h3>
              <form onSubmit={handleCreatePromoCode} className="promo-code-form">
                <div className="form-group">
                  <label>Code</label>
                  <input
                    type="text"
                    value={newPromoCode.code}
                    onChange={(e) => setNewPromoCode({
                      ...newPromoCode,
                      code: e.target.value.toUpperCase()
                    })}
                    placeholder="SUMMER2024"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Discount Percentage</label>
                  <input
                    type="number"
                    min="1"
                    max="100"
                    value={newPromoCode.discount_percentage}
                    onChange={(e) => setNewPromoCode({
                      ...newPromoCode,
                      discount_percentage: e.target.value
                    })}
                    placeholder="20"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Maximum Uses</label>
                  <input
                    type="number"
                    min="1"
                    value={newPromoCode.max_uses}
                    onChange={(e) => setNewPromoCode({
                      ...newPromoCode,
                      max_uses: e.target.value
                    })}
                    placeholder="100"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Expiration Date</label>
                  <input
                    type="datetime-local"
                    value={newPromoCode.expires_at}
                    onChange={(e) => setNewPromoCode({
                      ...newPromoCode,
                      expires_at: e.target.value
                    })}
                  />
                </div>

                <button type="submit" className="btn-primary">
                  Create Promo Code
                </button>
              </form>
            </div>

            <div className="promo-codes-list">
              <h3>Active Promo Codes</h3>
              <table>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Discount</th>
                    <th>Uses</th>
                    <th>Max Uses</th>
                    <th>Expires</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {promoCodes.map(code => (
                    <tr key={code.id}>
                      <td>{code.code}</td>
                      <td>{code.discount_percentage}%</td>
                      <td>{code.current_uses}</td>
                      <td>{code.max_uses}</td>
                      <td>
                        {code.expires_at 
                          ? new Date(code.expires_at).toLocaleDateString()
                          : 'Never'
                        }
                      </td>
                      <td>
                        <span className={`status-badge ${code.is_active ? 'active' : 'inactive'}`}>
                          {code.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>
                        {code.is_active && (
                          <button 
                            onClick={() => handleDeactivatePromoCode(code.id)}
                            className="btn-danger"
                          >
                            Deactivate
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {selectedTab === 'affiliates' && (
          <div className="affiliates-section space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="stat-card bg-white p-4 rounded-lg shadow">
                <h3 className="text-gray-600 text-sm">Total Affiliates</h3>
                <p className="text-2xl font-bold">{affiliates.length}</p>
              </div>
              <div className="stat-card bg-white p-4 rounded-lg shadow">
                <h3 className="text-gray-600 text-sm">Total Referrals</h3>
                <p className="text-2xl font-bold">
                  {affiliates.reduce((sum, aff) => sum + (aff.total_referrals || 0), 0)}
                </p>
              </div>
              <div className="stat-card bg-white p-4 rounded-lg shadow">
                <h3 className="text-gray-600 text-sm">Total Commissions</h3>
                <p className="text-2xl font-bold">
                  {formatCurrency(affiliates.reduce((sum, aff) => sum + (aff.total_earnings || 0), 0))}
                </p>
              </div>
              <div className="stat-card bg-white p-4 rounded-lg shadow">
                <h3 className="text-gray-600 text-sm">Pending Payments</h3>
                <p className="text-2xl font-bold text-orange-600">
                  {formatCurrency(pendingPayments.reduce((sum, payment) => sum + payment.amount, 0))}
                </p>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">Pending Payments</h3>
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b">
                    <th className="pb-2">Affiliate</th>
                    <th className="pb-2">Amount</th>
                    <th className="pb-2">MoMo Details</th>
                    <th className="pb-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingPayments.map(payment => (
                    <tr key={payment.id} className="border-b">
                      <td className="py-2">{payment.affiliate_name}</td>
                      <td className="py-2">{formatCurrency(payment.amount)}</td>
                      <td className="py-2">
                        <div>{payment.momo_number}</div>
                        <div className="text-sm text-gray-500">{payment.momo_name}</div>
                      </td>
                      <td className="py-2">
                        <button 
                          onClick={() => handleMarkAsPaid(payment.id)}
                          className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                        >
                          Mark as Paid
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">All Affiliates</h3>
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b">
                    <th className="pb-2">Name</th>
                    <th className="pb-2">Email</th>
                    <th className="pb-2">Referrals</th>
                    <th className="pb-2">Total Earned</th>
                    <th className="pb-2">Status</th>
                    <th className="pb-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {affiliates.map(affiliate => (
                    <tr key={affiliate.id} className="border-b">
                      <td className="py-2">{affiliate.name}</td>
                      <td className="py-2">{affiliate.email}</td>
                      <td className="py-2">{affiliate.total_referrals}</td>
                      <td className="py-2">{formatCurrency(affiliate.total_earnings)}</td>
                      <td className="py-2">
                        <span className={`px-2 py-1 rounded text-sm ${
                          affiliate.is_active 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {affiliate.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td className="py-2">
                        <button 
                          onClick={() => handleToggleAffiliateStatus(affiliate.id)}
                          className={`px-3 py-1 rounded ${
                            affiliate.is_active
                              ? 'bg-red-500 text-white hover:bg-red-600'
                              : 'bg-green-500 text-white hover:bg-green-600'
                          }`}
                        >
                          {affiliate.is_active ? 'Deactivate' : 'Activate'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard; 