import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useParams, useLocation } from 'react-router-dom'
import axios from 'axios'
import './BirthdayGallery.css'
import { GalleryThemeProvider, useGalleryTheme, defaultTheme } from '../components/GalleryThemeProvider'
import { MediaRenderer, isVideo } from '../components/MediaRenderer'

function BirthdayGalleryContent({ previewMode, providedGallery, providedPhotos }) {
  const theme = useGalleryTheme()
  const location = useLocation()
  const [gallery, setGallery] = useState(providedGallery || null)
  const [photos, setPhotos] = useState(providedPhotos || [])
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [loading, setLoading] = useState(!previewMode)
  const [error, setError] = useState(null)

  // Get subdomain from hostname
  const hostname = window.location.hostname
  const isLocalhost = hostname === 'localhost'
  const isMainDomain = hostname === 'myphotosgallery.com' || hostname === 'www.myphotosgallery.com'
  
  // Get subdomain from either hostname or path
  const subdomainFromHost = !isLocalhost && !isMainDomain ? hostname.split('.')[0] : null
  const subdomainFromPath = location.pathname.split('/')[1]
  
  // Use subdomain from host if available, otherwise from path
  const subdomain = subdomainFromHost || subdomainFromPath

  const galleryStyles = theme.backgroundColor ? { backgroundColor: theme.backgroundColor } : {}
  const headerStyles = theme.headerColor ? { backgroundColor: theme.headerColor } : {}
  const textStyles = theme.textColor ? { color: theme.textColor } : {}
  const fontStyles = theme.fontFamily ? { fontFamily: theme.fontFamily } : {}
  const accentStyles = theme.accentColor ? { color: theme.accentColor } : {}
  const cardStyles = theme.cardBackground ? { backgroundColor: theme.cardBackground } : {}

  useEffect(() => {
    // If in preview mode or gallery provided, use that
    if (previewMode || providedGallery) {
      setGallery(providedGallery)
      setPhotos(providedPhotos)
      setLoading(false)
      return
    }

    // Don't fetch if no subdomain
    if (!subdomain || (isLocalhost && !subdomainFromPath)) {
      setError('No gallery specified')
      setLoading(false)
      return
    }

    const fetchGalleryAndPhotos = async () => {
      try {
        // Fetch both gallery data and customization data
        const [galleryResponse, customizeResponse] = await Promise.all([
          axios.get(`${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}`),
          axios.get(`${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}/customize`)
        ])

        console.log('Gallery Response:', galleryResponse.data)
        console.log('Customize Response:', customizeResponse.data)

        setGallery({
          ...galleryResponse.data,
          customization: customizeResponse.data
        })

        if (galleryResponse.data.google_album_id) {
          const photosResponse = await axios.get(
            `${import.meta.env.VITE_API_URL}/api/albums/${galleryResponse.data.google_album_id}/photos`
          )
          setPhotos(photosResponse.data)
        }
        setLoading(false)
      } catch (error) {
        console.error('Birthday Gallery - Error fetching gallery:', error)
        setError('Failed to load gallery')
        setLoading(false)
      }
    }

    fetchGalleryAndPhotos()
  }, [subdomain, previewMode, providedGallery, providedPhotos])

  const handlePhotoClick = (photo, index) => {
    setSelectedPhoto(photo)
    setSelectedIndex(index)
  }

  const handleNext = (e) => {
    e.stopPropagation()
    const nextIndex = (selectedIndex + 1) % photos.length
    setSelectedPhoto(photos[nextIndex])
    setSelectedIndex(nextIndex)
  }

  const handlePrev = (e) => {
    e.stopPropagation()
    const prevIndex = selectedIndex === 0 ? photos.length - 1 : selectedIndex - 1
    setSelectedPhoto(photos[prevIndex])
    setSelectedIndex(prevIndex)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })
  }

  // Use the customization data from the gallery object
  const displayTitle = theme.title || gallery?.customization?.title || gallery?.title
  const displayDate = theme.date || gallery?.customization?.date || formatDate(gallery?.event_date)
  const displayPlace = theme.place || gallery?.customization?.place || gallery?.venue
  const displayTagline = theme.tagline || gallery?.customization?.tagline || gallery?.tagline
  const displayDivider = theme.divider || gallery?.customization?.divider || gallery?.divider_emoji

  if (loading) return <div className="loading-screen">Loading...</div>
  if (!gallery) return <div className="error-screen">Gallery not found</div>

  return (
    <div className="app" style={{ ...galleryStyles, ...fontStyles }}>
      <header className="birthday-header" style={headerStyles}>
        <div className="birthday-decorations">
          <span className="balloon">🎈</span>
          <span className="balloon">🎈</span>
          <span className="cake">🍰</span>
          <span className="cake">🍰</span>
          <span className="sparkle">✨</span>
          <span className="sparkle">✨</span>
          <span className="sparkle">✨</span>
        </div>
        <motion.div
          className="header-content"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
          style={textStyles}
        >
          <motion.span 
            className="event-icon"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.5 }}
          >
            🎂
          </motion.span>
          
          <motion.h1>{displayTitle}</motion.h1>

          <motion.div className="event-details">
            <div className="date-location">
              <motion.span 
                className="date"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                {displayDate}
              </motion.span>
              <motion.div className="divider">
                {displayDivider}
              </motion.div>
              <motion.span className="location">
                {displayPlace}
              </motion.span>
            </div>
            <motion.span 
              className="tagline"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1.3, duration: 0.8 }}
            >
              {displayTagline}
            </motion.span>
          </motion.div>
        </motion.div>
      </header>

      <motion.div className="photo-grid" style={cardStyles}>
        {photos.map((item, index) => (
          <motion.div
            key={item.id}
            className={`photo-item ${isVideo(item) ? 'video-item' : ''}`}
            style={cardStyles}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
          >
            <MediaRenderer 
              item={item} 
              isModal={false}
              onClick={() => handlePhotoClick(item, index)}
            />
          </motion.div>
        ))}
      </motion.div>

      <AnimatePresence>
        {selectedPhoto && (
          <motion.div 
            className="photo-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedPhoto(null)}
          >
            <motion.button
              className="nav-button prev"
              onClick={handlePrev}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              ❮
            </motion.button>

            <motion.div 
              className="modal-content"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.7}
              onDragEnd={(e, { offset, velocity }) => {
                if (Math.abs(offset.x) > 100) {
                  if (offset.x > 0) {
                    handlePrev(e)
                  } else {
                    handleNext(e)
                  }
                }
              }}
            >
              <MediaRenderer 
                item={selectedPhoto} 
                isModal={true}
                onClick={(e) => e.stopPropagation()}
              />
              <div className="photo-counter">
                {selectedIndex + 1} / {photos.length}
              </div>
              
              <div className="swipe-indicator">
                Swipe to navigate
              </div>
            </motion.div>

            <motion.button
              className="nav-button next"
              onClick={handleNext}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              ❯
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function BirthdayGallery({ previewMode, gallery: galleryProp, photos: photosProp }) {
  
  if (previewMode) {
    return (
      <GalleryThemeProvider theme={galleryProp?.customization}>
        <BirthdayGalleryContent 
          previewMode={true}
          providedGallery={galleryProp}
          providedPhotos={photosProp}
        />
      </GalleryThemeProvider>
    )
  }

  return (
    <GalleryThemeProvider>
      <BirthdayGalleryContent previewMode={false} />
    </GalleryThemeProvider>
  )
}

export default BirthdayGallery 