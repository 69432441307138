/**
 * Format a number as currency in GHS (Ghana Cedis)
 * @param {number} amount - The amount to format
 * @returns {string} Formatted amount (e.g., "GHS 100.00")
 */
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GH', {
    style: 'currency',
    currency: 'GHS',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

/**
 * Format a date to a readable string
 * @param {string|Date} date - The date to format
 * @returns {string} Formatted date (e.g., "Jan 1, 2024")
 */
export const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-GH', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

/**
 * Format a number with commas
 * @param {number} num - The number to format
 * @returns {string} Formatted number (e.g., "1,234")
 */
export const formatNumber = (num) => {
  return new Intl.NumberFormat('en-GH').format(num);
}; 