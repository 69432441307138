/**
 * GalleryRouter Component
 * 
 * This component handles routing for both the main domain (myphotosgallery.com) and 
 * dynamically created subdomains (e.g., viva.myphotosgallery.com).
 * 
 * Architecture:
 * -------------
 * 1. Cloudflare Worker:
 *    - Handles subdomain routing at the edge
 *    - Routes subdomain.myphotosgallery.com → myphotosgallery.com
 *    - Preserves asset MIME types and paths
 *    - API requests (api.myphotosgallery.com) bypass the Worker
 * 
 * 2. Nginx:
 *    - Handles SSL termination
 *    - Routes API subdomain to Express server
 *    - Proxies other requests to main domain
 * 
 * 3. This Component:
 *    - Detects if request is from subdomain or main domain
 *    - Fetches appropriate gallery data
 *    - Renders correct gallery component based on type
 * 
 * Flow:
 * -----
 * 1. User visits subdomain.myphotosgallery.com
 * 2. Cloudflare Worker serves main app
 * 3. This component detects subdomain
 * 4. Fetches gallery data from API
 * 5. Renders appropriate gallery component
 * 
 * @example
 * // Main domain: myphotosgallery.com/viva
 * // Subdomain: viva.myphotosgallery.com
 * // Both URLs will show the same content
 */

import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

// Import all gallery components
import Gallery from '../pages/Gallery';
import PartyGallery from '../pages/PartyGallery';
import GraduationGallery from '../pages/GraduationGallery';
import BirthdayGallery from '../pages/BirthdayGallery';
import GallerySuccess from '../pages/GallerySuccess';

function GalleryRouter() {
  const location = useLocation();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [galleryType, setGalleryType] = useState(null);
  const [galleryInfo, setGalleryInfo] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  // Get subdomain from hostname
  const hostname = window.location.hostname;
  const isLocalhost = hostname === 'localhost';
  const isMainDomain = hostname === 'myphotosgallery.com' || hostname === 'www.myphotosgallery.com';
  
  // Get subdomain from either hostname or path
  const subdomainFromHost = !isLocalhost && !isMainDomain ? hostname.split('.')[0] : null;
  const subdomainFromPath = location.pathname.split('/')[1];  // Get 'george' from '/george'
  
  // Use subdomain from host if available, otherwise from path
  const subdomain = subdomainFromHost || subdomainFromPath;

  const isNewGallery = new URLSearchParams(location.search).get('new') === 'true';

  useEffect(() => {
    const fetchGalleryType = async () => {
      if (isNewGallery) {
        setLoading(false);
        return;
      }

      // Don't fetch if no subdomain or if it's just "localhost"
      if (!subdomain || (isLocalhost && !subdomainFromPath)) {
        setError('No gallery specified');
        setLoading(false);
        return;
      }

      try {
        const galleryResponse = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}`
        );
        setGalleryType(galleryResponse.data.event_type);
        setGalleryInfo(galleryResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gallery:', error);
        
        if (isNewGallery && retryCount < 3) {
          setRetryCount(prev => prev + 1);
          setTimeout(() => {
            fetchGalleryType();
          }, 1000);
          return;
        }

        setError('Failed to load gallery');
        setLoading(false);
      }
    };

    fetchGalleryType();
  }, [subdomain, isNewGallery]);

  // Show loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  // Show error state
  if (error && !isNewGallery) {
    console.log('Rendering error state:', error);
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-lg text-red-600">{error}</div>
      </div>
    );
  }

  // If it's a new gallery, show the success page
  if (isNewGallery) {
    return <GallerySuccess subdomain={subdomain} />;
  }

  // Otherwise, show the appropriate gallery component
  switch (galleryType) {
    case 'wedding':
      return <Gallery gallery={galleryInfo} />;
    case 'party':
      return <PartyGallery gallery={galleryInfo} />;
    case 'graduation':
      return <GraduationGallery gallery={galleryInfo} />;
    case 'birthday':
      return <BirthdayGallery gallery={galleryInfo} />;
    case 'photoshoot':
      return <PartyGallery gallery={galleryInfo} />;
    default:
      console.log('Unknown gallery type:', galleryType);
      return <div>Unknown gallery type</div>;
  }
}

export default GalleryRouter; 