import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'

export const isVideo = (mediaItem) => {
  return mediaItem?.mimeType?.includes('video')
}

export const MediaRenderer = ({ item, isModal = false, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  // When in modal view, we want to preload the video
  useEffect(() => {
    if (isModal && isVideo(item)) {
      setIsLoaded(false)
    }
  }, [isModal, item])

  if (isVideo(item)) {
    // Split the URL to get the base part
    const baseUrl = item.url.split('=')[0]
    
    return (
      <motion.div 
        className="video-container"
        whileHover={{ scale: 1.02 }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <video 
          key={item.id}
          controls={isModal}
          preload={isModal ? "auto" : "metadata"}
          poster={`${baseUrl}=w400-tc`}
          muted={!isModal}
          playsInline
          className="media-item"
          onLoadedData={() => setIsLoaded(true)}
          onWaiting={() => setIsLoaded(false)}
          onPlaying={() => setIsLoaded(true)}
          onMouseEnter={(e) => {
            if (isHovered && !isModal) {
              e.currentTarget.src = item.url
              if (document.documentElement.hasAttribute('data-user-interacted')) {
                e.currentTarget.play().catch(() => {})
              }
            }
          }}
          onMouseLeave={(e) => {
            if (!isModal) {
              e.currentTarget.pause()
              e.currentTarget.currentTime = 0
              e.currentTarget.src = ''
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
            document.documentElement.setAttribute('data-user-interacted', 'true')
          }}
        >
          <source 
            src={item.url} 
            type={item.mimeType} 
          />
        </video>
        {!isModal}
      </motion.div>
    )
  }
  return (
    <img 
      src={item.url} 
      alt="Gallery moment" 
      className="media-item" 
      onClick={onClick}
    />
  )
}