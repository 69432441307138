import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import EmojiPicker from 'emoji-picker-react';
import PaymentForm from '../components/PaymentForm';
import { logUserActivity } from '../utils/analytics';
import './Setup.css';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { debounce } from 'lodash';

// Add these validation functions at the top of your file
const validateSubdomain = (subdomain) => {
  if (!subdomain) return { isValid: false, message: 'Subdomain is required' };
  if (subdomain.length > 63) return { isValid: false, message: 'Subdomain is too long (max 63 characters)' };
  
  // Check for reserved names
  const reservedNames = [
    'api',
    'www',
    'myphotosgallery',
    'admin',
    'dashboard'
  ];

  // Check if subdomain exactly matches any reserved name
  if (reservedNames.includes(subdomain.toLowerCase())) {
    return { 
      isValid: false, 
      message: 'This subdomain name is reserved and cannot be used' 
    };
  }

  // Check for patterns that start with www or myphotosgallery
  if (subdomain.toLowerCase().startsWith('www') || 
      subdomain.toLowerCase().startsWith('myphotosgallery') ||
      subdomain.toLowerCase().includes('myphotosgallery')) {
    return { 
      isValid: false, 
      message: 'This subdomain pattern is not allowed' 
    };
  }
  
  // Only allow lowercase letters, numbers, and hyphens
  const subdomainRegex = /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/;
  if (!subdomainRegex.test(subdomain)) {
    return { 
      isValid: false, 
      message: 'Subdomain can only contain lowercase letters, numbers, and hyphens. Cannot start or end with hyphen.' 
    };
  }
  
  return { isValid: true };
};

const checkAlbumName = (albumName) => {
  if (!albumName) return { isValid: false, message: 'Album name is required' };
  if (albumName.length > 100) return { isValid: false, message: 'Album name is too long (max 100 characters)' };
  
  // Basic sanitization - no special characters except spaces and basic punctuation
  const albumRegex = /^[a-zA-Z0-9\s\-_.,!'()]+$/;
  if (!albumRegex.test(albumName)) {
    return { 
      isValid: false, 
      message: 'Album name contains invalid characters' 
    };
  }
  
  return { isValid: true };
};

function Setup() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    event_type: '',
    title: '',
    event_date: '',
    venue: '',
    tagline: '',
    divider_emoji: '♥',
    subdomain: '',
    album_name: '',
    subscription_plan: 'monthly'
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isAlbumNameAvailable, setIsAlbumNameAvailable] = useState(null);
  const [isSubdomainAvailable, setIsSubdomainAvailable] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    subdomain: null,
    albumName: null
  });
  const [albumShared, setAlbumShared] = useState(false);
  const [checkingAlbum, setCheckingAlbum] = useState(false);
  const [validating, setValidating] = useState({
    subdomain: false,
    album: false
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [debouncedAlbumCheck] = useState(() => 
    debounce(async (value, token) => {
      // First validate the album name format
      const validation = checkAlbumName(value);
      if (!validation.isValid) {
        setValidationErrors(prev => ({ ...prev, albumName: validation.message }));
        setIsAlbumNameAvailable(false);
        return;
      }

      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/albums/check/${value}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        setIsAlbumNameAvailable(response.data.exists && response.data.inDatabase);
      } catch (error) {
        console.error('Error checking album name:', error);
        setIsAlbumNameAvailable(false);
      }
    }, 500)  // Wait 500ms after user stops typing
  );

  const [debouncedSubdomainCheck] = useState(() => 
    debounce(async (value, token) => {
      if (!value) {
        setValidationErrors(prev => ({ ...prev, subdomain: 'Subdomain is required' }));
        setIsSubdomainAvailable(false);
        return;
      }

      // Validate format locally first
      const validation = validateSubdomain(value);
      if (!validation.isValid) {
        setValidationErrors(prev => ({ ...prev, subdomain: validation.message }));
        setIsSubdomainAvailable(false);
        return;
      }

      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/galleries/check-subdomain/${value}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (!response.data.available) {
          setValidationErrors(prev => ({ 
            ...prev, 
            subdomain: 'This subdomain is already taken' 
          }));
          setIsSubdomainAvailable(false);
          return;
        }

        // Clear validation error if subdomain is valid and available
        setValidationErrors(prev => ({ ...prev, subdomain: null }));
        setIsSubdomainAvailable(true);
      } catch (error) {
        console.error('Error checking subdomain:', error);
        setValidationErrors(prev => ({ 
          ...prev, 
          subdomain: 'Error checking subdomain availability' 
        }));
        setIsSubdomainAvailable(false);
      }
    }, 500)  // Wait 500ms after user stops typing
  );

  const copyToClipboard = useCallback(async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  }, []);

  // Event type configurations
  const eventTypes = {
    wedding: {
      icon: '💒',
      fields: {
        title: {
          label: 'Couple Names',
          placeholder: 'e.g., Sarah & John'
        },
        event_date: {
          label: 'Wedding Date',
          type: 'date'
        },
        venue: {
          label: 'Wedding Venue',
          placeholder: 'Wedding Venue Name'
        },
        tagline: {
          label: 'Tagline',
          placeholder: 'e.g., Forever Begins Here'
        }
      }
    },
    birthday: {
      icon: '🎂',
      fields: {
        title: {
          label: 'Celebrant Name',
          placeholder: 'e.g., Sarah\'s 30th Birthday'
        },
        event_date: {
          label: 'Birthday Date',
          type: 'date'
        },
        venue: {
          label: 'Party Venue',
          placeholder: 'Celebration Venue'
        },
        tagline: {
          label: 'Birthday Message',
          placeholder: 'e.g., Thirty and Thriving!'
        }
      }
    },
    graduation: {
      icon: '🎓',
      fields: {
        title: {
          label: 'Graduate Name',
          placeholder: 'e.g., Sarah\'s Graduation'
        },
        event_date: {
          label: 'Graduation Date',
          type: 'date'
        },
        venue: {
          label: 'Institution',
          placeholder: 'School/University Name'
        },
        tagline: {
          label: 'Achievement',
          placeholder: 'e.g., Class of 2024 - BSc Computer Science'
        }
      }
    },
    photoshoot: {
      icon: '📸',
      fields: {
        title: {
          label: 'Collection Name',
          placeholder: 'e.g., Summer Portraits 2024'
        },
        event_date: {
          label: 'Shoot Date',
          type: 'date'
        },
        venue: {
          label: 'Location',
          placeholder: 'Shoot Location'
        },
        tagline: {
          label: 'Collection Theme',
          placeholder: 'e.g., Urban Fashion Series'
        }
      }
    }
  };

  useEffect(() => {
    // Check if user is logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  // Log page view when component mounts
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      logUserActivity(user, 'page_view', { page: 'setup', step });
    }
  }, [step]); // Log each step change as a new page view

  const validateAlbumName = async () => {
    if (!formData.album_name) return;
    
    setValidating(prev => ({ ...prev, album: true }));
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/albums/check/${formData.album_name}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setIsAlbumNameAvailable(response.data.exists && response.data.inDatabase);
      setAlbumShared(response.data.exists);
    } catch (error) {
      console.error('Error checking album:', error);
      setIsAlbumNameAvailable(false);
      setAlbumShared(false);
    } finally {
      setValidating(prev => ({ ...prev, album: false }));
    }
  };

  const checkSubdomainAvailability = async () => {
    if (!formData.subdomain || validationErrors.subdomain) return;

    try {
      setValidating(prev => ({ ...prev, subdomain: true }));
      
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/galleries/validate-subdomain/${formData.subdomain}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setIsSubdomainAvailable(response.data.available);
      
      // Log subdomain check
      if (auth.currentUser) {
        await logUserActivity(auth.currentUser, 'setup_step', {
          step,
          action: 'subdomain_check',
          subdomain: formData.subdomain,
          available: response.data.available
        });
      }
    } catch (error) {
      console.error('Error checking subdomain:', error);
      setIsSubdomainAvailable(false);
    } finally {
      setValidating(prev => ({ ...prev, subdomain: false }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({ ...prev, [name]: value }));

    // If the subdomain field is being changed
    if (name === 'subdomain') {
      // Convert to lowercase and remove invalid characters
      const sanitizedValue = value.toLowerCase().replace(/[^a-z0-9-]/g, '');
      
      if (value !== sanitizedValue) {
        setFormData(prev => ({ ...prev, subdomain: sanitizedValue }));
      }

      // Check for dots
      if (sanitizedValue.includes('.')) {
        setValidationErrors(prev => ({ 
          ...prev, 
          subdomain: 'Subdomain cannot contain dots (.) - Please use hyphens (-) instead' 
        }));
        setIsSubdomainAvailable(false);
        return;
      }

      // Check availability with server if value is valid
      if (sanitizedValue) {
        const token = auth.currentUser ? auth.currentUser.accessToken : null;
        if (token) {
          debouncedSubdomainCheck(sanitizedValue, token);
        }
      } else {
        setValidationErrors(prev => ({ ...prev, subdomain: 'Subdomain is required' }));
        setIsSubdomainAvailable(false);
      }
    }

    if (name === 'album_name') {
      // Check availability with server if value is valid
      if (value) {
        const token = auth.currentUser ? auth.currentUser.accessToken : null;
        if (token) {
          debouncedAlbumCheck(value, token);
        }
      } else {
        setValidationErrors(prev => ({ ...prev, albumName: 'Album name is required' }));
        setIsAlbumNameAvailable(false);
      }
    }

    // Reset availability state when input changes
    if (name === 'album_name') setIsAlbumNameAvailable(null);
    if (name === 'subdomain') setIsSubdomainAvailable(null);
  };

  // Helper function to validate subdomain format
  const validateSubdomainFormat = (subdomain) => {
    if (!subdomain) {
      return { isValid: false, message: 'Subdomain is required' };
    }
    
    // Only allow lowercase letters, numbers, and hyphens
    const validFormat = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    if (!validFormat.test(subdomain)) {
      return { 
        isValid: false, 
        message: 'Only use lowercase letters, numbers, and hyphens. Cannot start or end with a hyphen.' 
      };
    }

    // Check length
    if (subdomain.length < 3) {
      return { isValid: false, message: 'Subdomain must be at least 3 characters long' };
    }

    if (subdomain.length > 63) {
      return { isValid: false, message: 'Subdomain must be less than 63 characters' };
    }

    return { isValid: true, message: null };
  };

  const handleEmojiSelect = (emoji) => {
    setFormData(prev => ({ ...prev, divider_emoji: emoji.emoji }));
    setShowEmojiPicker(false);
  };

  const handleEventTypeSelect = async (type) => {
    const user = auth.currentUser;
    if (user) {
      await logUserActivity(user, 'setup_step', {
        step,
        action: 'event_type_selected',
        selected_type: type
      });
    }
    setFormData(prev => ({ ...prev, event_type: type }));
    handleNext();
  };

  const handleNext = async () => {
    const user = auth.currentUser;
    if (user) {
      await logUserActivity(user, 'setup_step', {
        step: step + 1,
        completed_step: step,
        event_type: formData.event_type
      });
    }
    setStep(step + 1);
  };

  const handleBack = async () => {
    const user = auth.currentUser;
    if (user) {
      await logUserActivity(user, 'setup_step', {
        step: step - 1,
        action: 'back'
      });
    }
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Not authenticated');
      }
      
      const token = await auth.currentUser.getIdToken();
      
      // Create the gallery with all the info
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/galleries`,
        {
          event_type: formData.event_type,
          title: formData.title,
          event_date: formData.event_date,
          venue: formData.venue,
          tagline: formData.tagline,
          divider_emoji: formData.divider_emoji,
          subdomain: formData.subdomain,
          album_name: formData.album_name
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      // Navigate to the new gallery
      navigate(`/${formData.subdomain}?new=true`);
    } catch (error) {
      console.error('Error creating gallery:', error);
      // Handle error (show message to user, etc.)
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const renderEventTypeSelection = () => (
    <motion.div 
      className="setup-step"
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
    >
      <h2>Choose Event Type</h2>
      <div className="event-type-grid">
        {Object.entries(eventTypes).map(([type, config]) => (
          <button
            key={type}
            className={`event-type-button ${formData.event_type === type ? 'selected' : ''}`}
            onClick={() => handleEventTypeSelect(type)}
          >
            <span className="event-icon">{config.icon}</span>
            <span className="event-name">{type}</span>
          </button>
        ))}
      </div>
    </motion.div>
  );

  const renderBasicInfo = () => {
    const eventConfig = eventTypes[formData.event_type];
    if (!eventConfig) return null;

    return (
      <motion.div 
        className="setup-step"
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -300, opacity: 0 }}
      >
        <h2>Basic Information</h2>
        {Object.entries(eventConfig.fields).map(([fieldName, config]) => (
          <div key={fieldName} className="form-group">
            <label>{config.label}</label>
            <input
              type={config.type || 'text'}
              name={fieldName}
              placeholder={config.placeholder}
              value={formData[fieldName]}
              onChange={handleInputChange}
              required
            />
          </div>
        ))}

        {/* Only show emoji selector for wedding events */}
        {formData.event_type === 'wedding' && (
          <div className="emoji-selector">
            <label>Choose Divider Symbol</label>
            <button 
              type="button" 
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="emoji-button"
            >
              {formData.divider_emoji}
            </button>
            {showEmojiPicker && (
              <div className="emoji-picker-container">
                <EmojiPicker onEmojiClick={handleEmojiSelect} />
              </div>
            )}
          </div>
        )}

        <div className="navigation-buttons">
          <button 
            className="btn btn-secondary" 
            onClick={handleBack}
            style={{ marginRight: '10px' }}
          >
            Back
          </button>
          <button 
            className="btn btn-primary" 
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </motion.div>
    );
  };

  const renderAlbumStep = () => (
    <motion.div 
      className="setup-step"
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
    >
      <h2>Choose Your Album Name</h2>
      
      <div className="form-group">
        <label>Album Name</label>
        <div className="input-group">
          <input
            type="text"
            name="album_name"
            placeholder="Enter album name"
            value={formData.album_name}
            onChange={handleInputChange}
            required
            className={`modern-input ${
              isAlbumNameAvailable === true ? 'valid' :
              isAlbumNameAvailable === false ? 'invalid' : ''
            }`}
          />
          <button
            onClick={validateAlbumName}
            disabled={!formData.album_name || validationErrors.albumName || validating.album}
            className={`validate-button ${validating.album ? 'validating' : ''}`}
          >
            {validating.album ? (
              <span className="spinner"></span>
            ) : (
              'Validate'
            )}
          </button>
        </div>
        {validationErrors.albumName && (
          <span className="error-message">{validationErrors.albumName}</span>
        )}
        <div className="alert alert-warning p-4 text-sm md:text-base flex flex-col sm:flex-row items-start sm:items-center gap-2 bg-yellow-50 border border-yellow-200 rounded-lg">
          <span className="text-gray-900 font-medium">Please share your album with</span>
          <div className="w-full sm:w-auto flex items-center gap-2">
            <button 
              className="copy-email-btn w-full sm:w-auto inline-flex items-center justify-center px-3 py-2 bg-white rounded-md border border-yellow-200 hover:bg-yellow-50 transition-colors"
              onClick={() => copyToClipboard('myphotosgallerygh@gmail.com')}
            >
              <span className="text-blue-600 font-medium">myphotosgallerygh@gmail.com</span>
              <span className="copy-icon ml-2">📋</span>
              {copySuccess && (
                <span className="copy-tooltip absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap">
                  Copied!
                </span>
              )}
            </button>
          </div>
          <span className="text-gray-900 font-medium">in Google Photos</span>
        </div>
        {isAlbumNameAvailable !== null && (
          <span className={`availability-indicator ${isAlbumNameAvailable ? 'available' : 'unavailable'}`}>
            {isAlbumNameAvailable ? '✓ Album found' : '✗ Album not found or not shared'}
          </span>
        )}
      </div>

      <div className="navigation-buttons">
        <button className="btn btn-secondary" onClick={handleBack}>Back</button>
        <button 
          className="btn btn-primary" 
          onClick={handleNext}
          disabled={!isAlbumNameAvailable || !albumShared || !!validationErrors.albumName}
        >
          Next
        </button>
      </div>
    </motion.div>
  );

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderEventTypeSelection();
      case 1:
        return renderBasicInfo();
      case 2:
        return renderAlbumStep();
      case 3:
        return (
          <motion.div 
            className="setup-step"
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
          >
            <h2 className="text-gray-900">Choose Your Gallery Name</h2>
            <div className="form-group">
              <label className="text-gray-900 font-medium">Gallery Name</label>
              
              <div className="bg-white p-4 rounded-lg border border-gray-200 space-y-4 mt-2">
                <div className="flex items-center gap-2">
                  <span className="text-yellow-400">💡</span>
                  <span className="text-gray-900">This will be your gallery's web address.</span>
                </div>

                <div className="space-y-2">
                  <div className="text-gray-900">
                    For example, if you enter: <span className="font-medium">rihanna</span>
                  </div>
                  
                  <div className="text-gray-900">Your gallery link will be:</div>
                  
                  <div className="font-mono text-gray-900 bg-gray-50 p-2 rounded border border-gray-200">
                    rihanna.myphotosgallery.com
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="text-gray-700 font-medium">Tips:</div>
                  <ul className="list-disc ml-4 text-gray-600 space-y-1">
                    <li>Use only letters, numbers, and hyphens</li>
                    <li>Keep it short and memorable</li>
                    <li>Avoid spaces (use hyphens instead)</li>
                  </ul>
                </div>
              </div>

              <div className="mt-4">
                <input
                  type="text"
                  name="subdomain"
                  placeholder="your-gallery-name"
                  value={formData.subdomain}
                  onChange={handleInputChange}
                  required
                  className={`
                    w-full px-4 py-2 text-lg rounded-lg border
                    ${isSubdomainAvailable === true ? 'border-green-500 focus:ring-green-500' : 
                      isSubdomainAvailable === false ? 'border-red-500 focus:ring-red-500' : 
                      'border-gray-300 focus:ring-blue-500'
                    }
                    focus:outline-none focus:ring-2
                  `}
                />
                {isSubdomainAvailable !== null && (
                  <div className={`mt-1 text-sm ${isSubdomainAvailable ? 'text-green-600' : 'text-red-600'}`}>
                    {isSubdomainAvailable ? '✓ Available' : '✗ Already taken'}
                  </div>
                )}
              </div>

              {/* Live Preview */}
              <div className="mt-3 space-y-1">
                <div className="text-sm text-gray-600">Your gallery will be available at:</div>
                <div className="font-mono text-gray-900 bg-gray-50 p-2 rounded border border-gray-200">
                  {formData.subdomain ? 
                    `${formData.subdomain}.myphotosgallery.com` : 
                    'your-gallery-name.myphotosgallery.com'
                  }
                </div>
              </div>
            </div>

            <div className="navigation-buttons">
              <button 
                className="btn btn-secondary" 
                onClick={handleBack}
              >
                Back
              </button>
              <button 
                className="btn btn-primary" 
                onClick={handleNext}
                disabled={!formData.subdomain || !isSubdomainAvailable || !!validationErrors.subdomain}
              >
                Next
              </button>
            </div>
          </motion.div>
        );

      case 4:
        return (
          <motion.div 
            className="setup-step"
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
          >
            <h2>Choose Your Plan</h2>
            <PaymentForm 
              onPaymentComplete={async (paymentData) => {
                const user = auth.currentUser;
                if (user) {
                  await logUserActivity(user, 'payment_attempt', {
                    status: 'success',
                    plan: formData.subscription_plan
                  });
                }
                try {
                  const token = await auth.currentUser.getIdToken();

                  // Wait a moment for gallery creation to complete
                  await new Promise(resolve => setTimeout(resolve, 1500));

                  // Save the initial customization data using the same structure as CustomizeGallery.jsx
                  await axios.put(
                    `${import.meta.env.VITE_API_URL}/api/galleries/${formData.subdomain}/customize`,
                    {
                      // Gallery details (matching galleries table columns)
                      title: formData.title,
                      event_date: formData.event_date,    // matches event_date in schema
                      venue: formData.venue,              // matches venue in schema
                      tagline: formData.tagline,          // matches tagline in schema
                      divider_emoji: formData.divider_emoji, // matches divider_emoji in schema
                      // Theme customization (matching JSONB customization column structure)
                      customization: {
                        // Include gallery details in customization for theme
                        title: formData.title,
                        date: formData.event_date,
                        place: formData.venue,
                        tagline: formData.tagline,
                        divider: formData.divider_emoji,
                        // Theme properties
                        fontFamily: 'Inter',
                        backgroundColor: null,
                        headerColor: null,
                        textColor: null,
                        accentColor: null,
                        cardBackground: null
                      }
                    },
                    {
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                      }
                    }
                  );

                  navigate(`/${formData.subdomain}?new=true`);
                } catch (error) {
                  console.error('Error:', error);
                  setError('Failed to complete setup');
                }
              }}
              galleryData={{
                event_type: formData.event_type,
                title: formData.title,
                event_date: formData.event_date,
                venue: formData.venue,
                tagline: formData.tagline,
                divider_emoji: formData.divider_emoji,
                subdomain: formData.subdomain,
                album_name: formData.album_name
              }}
            />
            {error && <div className="error-message">{error}</div>}
            
            <div className="navigation-buttons">
              <button 
                className="btn btn-secondary" 
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          </motion.div>
        );
    }
  };

  return (
    <div className="setup-page">
      <div className="setup-header">
        <button 
          onClick={handleBackToHome}
          className="back-to-home"
        >
          ← Back to Home
        </button>
      </div>
      <div className="setup-content">
        <div className="progress-bar">
          {[1, 2, 3, 4].map((i) => (
            <div 
              key={i}
              className={`step-indicator ${
                i < step ? 'completed' : i === step ? 'active' : ''
              }`}
            >
              {i}
            </div>
          ))}
        </div>
        {renderStep()}
      </div>
    </div>
  );
}

// Add some CSS for the new elements
const styles = `
.input-group {
  position: relative;
  margin-bottom: 5px;
}

.availability-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.availability-indicator.available {
  color: #4CAF50;
}

.availability-indicator.unavailable {
  color: #f44336;
}

input.valid {
  border-color: #4CAF50;
}

input.invalid {
  border-color: #f44336;
}

.help-text {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
}

.copy-email-btn {
  background: none;
  border: none;
  padding: 2px 4px;
  margin: 0;
  font: inherit;
  color: #2563eb;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.copy-email-btn:hover {
  background-color: rgba(37, 99, 235, 0.1);
}

.copy-icon {
  font-size: 14px;
  opacity: 0.7;
}

.copy-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  animation: fadeInOut 2s ease;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.setup-header {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}

.back-to-home {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  color: #666;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.back-to-home:hover {
  color: #000;
}

.setup-content {
  padding-top: 4rem;
}
`;

export default Setup; 
