import { createContext, useContext } from 'react';

const ThemeContext = createContext();

export const defaultTheme = {
  // Text content
  title: '',
  date: '',
  place: '',
  tagline: '',
  divider: '•', // Default divider symbol
  fontFamily: 'Inter',
  // Commented out for later implementation
  // backgroundColor: '#f6f9fc',
  // headerColor: '#1a1a1a',
  // textColor: '#4a4a4a',
  // accentColor: '#3b82f6',
  // cardBackground: '#ffffff',
};

export function GalleryThemeProvider({ theme = defaultTheme, children }) {
  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useGalleryTheme() {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error('useGalleryTheme must be used within a GalleryThemeProvider');
  }
  return theme;
} 