import { useState, useEffect } from 'react';
import './PaymentForm.css';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const validatePhoneNumber = (phone) => {
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length !== 10) {
    return 'Phone number must be 10 digits';
  }
  // MTN prefixes in Ghana
  const mtnPrefixes = ['024', '054', '055', '059'];
  const prefix = cleaned.substring(0, 3);
  if (!mtnPrefixes.includes(prefix)) {
    return 'Please enter a valid MTN number';
  }
  return null;
};

function PaymentForm({ onPaymentComplete, galleryData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValid, setPromoCodeValid] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const plans = {
    monthly: {
      name: 'Monthly',
      price: 20,
      description: 'Billed monthly'
    },
    biannual: {
      name: 'Bi-annual',
      price: 100,
      description: 'Save 16% - Billed every 6 months'
    },
    annual: {
      name: 'Annual',
      price: 180,
      description: 'Save 25% - Billed yearly'
    }
  };

  const validatePromoCode = async () => {
    if (!promoCode) return;

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/payments/validate-promo/${promoCode}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.valid) {
        setPromoCodeValid(true);
        setPromoCodeError(null);
        setDiscountPercentage(response.data.discount_percentage);
        const discountedPrice = plans[selectedPlan].price * (1 - response.data.discount_percentage / 100);
        setFinalPrice(Math.round(discountedPrice));
      }
    } catch (err) {
      setPromoCodeValid(false);
      setPromoCodeError(err.response?.data?.error || 'Invalid promo code');
      setDiscountPercentage(0);
      setFinalPrice(plans[selectedPlan].price);
    }
  };

  useEffect(() => {
    const basePrice = plans[selectedPlan].price;
    const discounted = discountPercentage ? basePrice * (1 - discountPercentage / 100) : basePrice;
    setFinalPrice(Math.round(discounted));
  }, [selectedPlan, discountPercentage]);

  const calculatePrice = (planPrice) => {
    if (discountPercentage > 0) {
      return Math.round(planPrice * (1 - discountPercentage / 100));
    }
    return planPrice;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setPhoneError(null);

    if (!galleryData) {
      setError('Gallery data is required');
      setLoading(false);
      return;
    }

    // Validate phone number
    const phoneValidationError = validatePhoneNumber(phoneNumber);
    if (phoneValidationError) {
      setPhoneError(phoneValidationError);
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('You must be logged in to make a payment');
      }

      const token = await user.getIdToken();
      
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/api/payments/initiate`,
        {
          plan: selectedPlan,
          phoneNumber: phoneNumber,
          galleryData: galleryData,
          promoCode: promoCodeValid ? promoCode : null
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      onPaymentComplete(response.data);
      setPaymentStatus('Please check your phone for the payment prompt');
    } catch (err) {
      console.error('Error:', err);
      setError(err.response?.data?.error || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-form">
      <div className="plan-selection">
        {Object.entries(plans).map(([planId, plan]) => {
          const planFinalPrice = calculatePrice(plan.price);
          
          return (
            <div 
              key={planId}
              className={`plan-option ${selectedPlan === planId ? 'selected' : ''}`}
              onClick={() => setSelectedPlan(planId)}
            >
              <h3>{plan.name}</h3>
              <p className="price">
                GHS {planFinalPrice}
                {discountPercentage > 0 && (
                  <>
                    <span className="original-price">GHS {plan.price}</span>
                    <span className="discount-badge">{discountPercentage}% OFF</span>
                  </>
                )}
              </p>
              <p className="description">{plan.description}</p>
            </div>
          );
        })}
      </div>

      <form onSubmit={handleSubmit} className="payment-details">
        <div className="form-group promo-code-group">
          <label htmlFor="promoCode">Have a promo code?</label>
          <div className="promo-code-input">
            <input
              type="text"
              id="promoCode"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
              placeholder="Enter promo code"
              className={promoCodeValid === true ? 'valid' : promoCodeValid === false ? 'invalid' : ''}
            />
            <button 
              type="button"
              onClick={validatePromoCode}
              className="btn btn-secondary"
              disabled={!promoCode}
            >
              Apply
            </button>
          </div>
          {promoCodeValid && (
            <div className="promo-success">
              Promo code applied! {discountPercentage}% discount
            </div>
          )}
          {promoCodeError && (
            <div className="error-message">{promoCodeError}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">MTN Mobile Money Number</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your MTN MoMo number"
            required
            pattern="[0-9]{10}"
            title="Please enter a valid 10-digit phone number"
          />
        </div>

        <button 
          type="submit" 
          disabled={loading || !phoneNumber}
          className="btn btn-primary"
        >
          {loading ? 'Processing...' : `Pay GHS ${calculatePrice(plans[selectedPlan].price)}`}
        </button>

        {error && (
          <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
            {error}
          </div>
        )}
        {phoneError && <div className="error-message">{phoneError}</div>}
        {paymentStatus && <div className="status-message">{paymentStatus}</div>}
      </form>
    </div>
  );
}

export default PaymentForm; 