import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from 'axios';

export const Register = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const referralCode = searchParams.get('ref');

  // Store referral code in localStorage before Google auth
  useEffect(() => {
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  const handleGoogleSignIn = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      
      // Force account selection
      provider.setCustomParameters({
        prompt: 'select_account'
      });

      const result = await signInWithPopup(auth, provider);
      
      if (result.user) {
        // Get stored referral code
        const storedReferralCode = localStorage.getItem('referralCode');
        
        if (storedReferralCode) {
          // Link referral to user
          const token = await result.user.getIdToken();
          await axios.post(
            `${import.meta.env.VITE_API_URL}/auth/link-referral`,
            { referralCode: storedReferralCode },
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          
          // Clear stored referral code
          localStorage.removeItem('referralCode');
        }
        
        navigate('/setup');
      }
    } catch (error) {
      console.error('Sign in error:', error);
      setError(error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6">
      {referralCode && (
        <div className="bg-blue-50 p-4 rounded-lg mb-4">
          <p className="text-blue-700">
            You're signing up with a referral code
          </p>
        </div>
      )}

      <button
        onClick={handleGoogleSignIn}
        className="w-full flex items-center justify-center gap-2 bg-white text-gray-700 border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50"
      >
        <img 
          src="/google-icon.svg" 
          alt="Google" 
          className="w-6 h-6" 
        />
        Continue with Google
      </button>

      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
    </div>
  );
}; 