import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { formatCurrency } from '../utils/format';

export const AffiliateDashboard = () => {
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [affiliateData, setAffiliateData] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    loadAffiliateData();
  }, []);

  const loadAffiliateData = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/api/affiliates/dashboard`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setAffiliateData(response.data);
    } catch (error) {
      console.error('Error loading affiliate data:', error);
      setError('Failed to load affiliate dashboard');
    } finally {
      setIsLoading(false);
    }
  };

  const copyReferralLink = async () => {
    const referralLink = `${window.location.origin}/register?ref=${affiliateData.code}`;
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <p className="text-red-700">{error}</p>
      </div>
    );
  }

  if (!affiliateData) return null;

  return (
    <div className="space-y-6">
      {/* Referral Link Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Your Referral Link</h3>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={`${window.location.origin}/register?ref=${affiliateData.code}`}
            className="flex-1 px-3 py-2 border border-gray-300 rounded bg-gray-50"
            readOnly
          />
          <button
            onClick={copyReferralLink}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            {copySuccess ? 'Copied!' : 'Copy'}
          </button>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white p-6 rounded-lg shadow">
          <h4 className="text-sm text-gray-500">Total Referrals</h4>
          <p className="text-2xl font-bold">{affiliateData.stats.totalReferrals}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h4 className="text-sm text-gray-500">Total Earnings</h4>
          <p className="text-2xl font-bold">{formatCurrency(affiliateData.stats.totalEarnings)}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h4 className="text-sm text-gray-500">Pending Payments</h4>
          <p className="text-2xl font-bold">{formatCurrency(affiliateData.stats.pendingPayments)}</p>
        </div>
      </div>

      {/* Recent Referrals */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Recent Referrals</h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b">
                <th className="pb-2">Date</th>
                <th className="pb-2">User</th>
                <th className="pb-2">Status</th>
                <th className="pb-2">Commission</th>
              </tr>
            </thead>
            <tbody>
              {affiliateData.recentReferrals.map((referral) => (
                <tr key={referral.id} className="border-b">
                  <td className="py-2">{new Date(referral.date).toLocaleDateString()}</td>
                  <td className="py-2">{referral.userName}</td>
                  <td className="py-2">
                    <span className={`px-2 py-1 rounded text-sm ${
                      referral.status === 'paid' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {referral.status}
                    </span>
                  </td>
                  <td className="py-2">{formatCurrency(referral.commission)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Payment Details */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Payment Details</h3>
        <div className="space-y-2">
          <p>
            <span className="font-medium">MoMo Number:</span> {affiliateData.paymentDetails.momoNumber}
          </p>
          <p>
            <span className="font-medium">MoMo Name:</span> {affiliateData.paymentDetails.momoName}
          </p>
        </div>
      </div>
    </div>
  );
}; 