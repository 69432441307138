import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { signInWithGoogle, signOut } from '../utils/firebase';
import { useAuth } from '../contexts/AuthContext';
import { logUserActivity } from '../utils/analytics';
import './Landing.css';

function Landing() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Log page view when component mounts
  useEffect(() => {
    if (user) {
      logUserActivity(user, 'page_view', { page: 'landing' });
    }
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut();
      if (user) {
        await logUserActivity(user, 'sign_out');
      }
      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const user = await signInWithGoogle();

      if (user) {
        await logUserActivity(user, 'sign_in');
        
        // Create or get user in our backend
        const token = await user.getIdToken();
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/users`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: user.email,
            google_id: user.uid,
            name: user.displayName
          })
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to create user');
        }

        // If successful, navigate to setup
        navigate('/');
      }
    } catch (error) {
      console.error('Sign in error:', error);
      setError(error.message || 'Failed to sign in');
    } finally {
      setLoading(false);
    }
  };

  const faqData = [
    {
      question: "How does MyPhotos Gallery work?",
      answer: "Our platform seamlessly integrates with Google Photos to create beautiful, shareable galleries. Simply sign in with your Google account, select your photos into an album, customize your gallery, and share it with friends and family."
    },
    {
      question: "Is my data secure?",
      answer: "Yes! We take security seriously. Your photos remain in your Google Photos account, and we only access what you explicitly share. We use industry-standard encryption and never store your photos/videos on our servers."
    },
    {
      question: "How much does it cost?",
      answer: "We offer flexible pricing plans to suit your needs. Our basic plan starts at GHC20/month, with options for biannual (GHC100) and annual (GHC180) subscriptions. All plans include one MyPhotosGallery domain, one gallery and unlimited photo/video in your Google Photos album."
    },
    {
      question: "Can I customize my gallery?",
      answer: "Absolutely! You can customize your gallery's look and feel, including colors, layout, and even add your own branding. Each gallery gets a unique URL that you can share with anyone."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We currently accept Mobile Money payments for secure and convenient transactions. More payment options will be available soon."
    },
    {
      question: "How long do galleries stay active?",
      answer: "Your galleries remain active as long as your subscription is active. You can create, edit, or delete galleries at any time during your subscription period."
    },
    {
      question: "How do I share my Google Photos album?",
      answer: `To share your Google Photos album:

1. Create and organize your album in Google Photos

2. Click the share icon (usually at the top right)

3. Enter our email:
   myphotosgallerygh@gmail.com

4. Click 'Send' to share

✨ Pro tip: Make sure to use the same album name when setting up your gallery!

Need help? Contact us at support@myphotosgallery.com`
    }
  ];

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <motion.div 
        className="hero-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="logo-container">
          <motion.img 
            src="/logo.svg" 
            alt="MyPhotos Gallery Logo" 
            className="hero-logo"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 100,
              damping: 10,
              delay: 0.2
            }}
          />
        </div>
        <h1>MyPhotos Gallery</h1>
        <p className="hero-subtitle">Create beautiful, shareable galleries for your memorable events</p>
        
        {user ? (
          <div className="flex gap-4">
            <motion.button
              className="bg-blue-600 text-white"
              onClick={() => navigate('/setup')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Create New Gallery
            </motion.button>
            <motion.button
              className="bg-gray-600 text-white"
              onClick={() => navigate('/profile')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              My Profile
            </motion.button>
            {user.admin && (
              <motion.button
                className="bg-purple-600 text-white"
                onClick={() => navigate('/admin')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Admin Dashboard
              </motion.button>
            )}
            <motion.button
              className="bg-red-600 text-white"
              onClick={handleSignOut}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Sign Out
            </motion.button>
          </div>
        ) : (
          <motion.button
            className="google-sign-in"
            onClick={handleGoogleSignIn}
            disabled={loading}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img 
              src="/google-icon.svg" 
              alt="" 
              className="google-icon"
            />
            {loading ? 'Signing in...' : 'Sign in with Google'}
          </motion.button>
        )}
        
        {error && <p className="error-message">{error}</p>}
      </motion.div>

      {/* Features Section */}
      <motion.section 
        className="features-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <h2>Create Your Gallery in Minutes</h2>
        <div className="setup-steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Basic Information</h3>
            <p>Enter event details, date, venue, and customize your gallery's look</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Choose Album</h3>
            <p>Select a name for your Google Photos album to store your precious memories</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Gallery URL</h3>
            <p>Pick a unique web address for your gallery to share with everyone</p>
          </div>
          <div className="step">
            <div className="step-number">4</div>
            <h3>Share & Enjoy</h3>
            <p>Share your gallery link and let everyone relive the magical moments</p>
          </div>
        </div>
      </motion.section>

      {/* Demo Section */}
      <motion.section 
        className="demo-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
      >
        <h2>See How It Works</h2>
        <div className="video-container" style={{ position: 'relative', paddingBottom: '54.6875%', height: 0 }}>
          <video
            controls
            defaultPlaybackRate={1.5}
            onLoadedData={(e) => {
              // Set playback rate when video loads
              e.target.playbackRate = 1.5;
            }}
            onError={(e) => {
              // If video fails to load, replace with Loom embed
              const videoContainer = e.target.parentElement;
              videoContainer.innerHTML = `
                <iframe 
                  src="https://www.loom.com/embed/394bf3e98e1e4d9785944627c2a8f6e0?sid=cba8f848-f85a-4e8c-94a1-fbf5cf7af6a9" 
                  frameborder="0" 
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowfullscreen
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                ></iframe>
              `;
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          >
            <source src="/Create Stunning Photo Galleries.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </motion.section>

      {/* About Section */}
      <motion.section 
        className="about-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.9 }}
      >
        <h2>About MyPhotos Gallery</h2>
        <p>
          We believe that life's special moments should be easily accessible and beautifully presented. 
          Our platform seamlessly integrates with Google Photos to create stunning, shareable 
          galleries that preserve your precious memories for years to come.
        </p>
      </motion.section>

      {/* Terms Section */}
      <motion.section 
        className="terms-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
      >
        <h3>Terms & Privacy</h3>
        <div className="terms-content">
          <p className="terms-intro">
            By using MyPhotos Gallery, you agree to our terms of service and privacy policy. 
            We respect your privacy and ensure your photos remain secure through Google Photos' 
            trusted platform.
          </p>
          
          <div className="terms-grid">
            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">🔒</span>
              <h4>Photo/Video Storage</h4>
              <p>We do not store any photos/videos on our servers. All photos/videos are stored in your Google Photos account.</p>
            </motion.div>

            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">🛡️</span>
              <h4>Data Protection</h4>
              <p>We do not sell your data to third parties.</p>
            </motion.div>

           {/* <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">🎯</span>
              <h4>Data Usage</h4>
              <p>We do not use your data for any purpose other than to create your gallery.</p>
            </motion.div> */}

            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">🎯</span>
              <h4>Ad-Free Experience</h4>
              <p>We do not advertise on your gallery.</p>
            </motion.div>

            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">📸</span>
              <h4>Photo/Video Usage</h4>
              <p>You grant us permission to use your photos/videos solely for the purpose of creating your gallery.</p>
            </motion.div>
            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">🔗</span>
              <h4>Photo/Video Sharing</h4>
              <p>You can share your gallery with anyone you want.</p>
            </motion.div>
            <motion.div 
              className="terms-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="terms-icon">💳</span>
              <h4>Secure Payment</h4>
              <p>We accept Mobile Money for payment in secure and fast way.</p>
            </motion.div>
          </div>
        </div>
      </motion.section>


      {/* FAQ Section */}
      <motion.section 
        className="faq-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.8 }}
      >
        <h2>Frequently Asked Questions</h2>
        <div className="faq-grid">
          {faqData.map((faq, index) => (
            <motion.div 
              key={index}
              className="faq-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * index }}
              whileHover={{ scale: 1.02 }}
            >
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Contact Section */}
      <motion.section 
        className="contact-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5 }}
      >
        <h3>Contact Us</h3>
        <div className="contact-content">
          <div className="contact-info">
            <motion.div 
              className="contact-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="contact-icon">📧</span>
              <h4>Email</h4>
              <a href="mailto:contact@myphotosgallery.com">contact@myphotosgallery.com</a>
            </motion.div>

            <motion.div 
              className="contact-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="contact-icon">💬</span>
              <h4>Social Media</h4>
              <div className="social-links">
                <a href="https://x.com/myphotosgh" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="social-link"
                >
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                  <span>Twitter</span>
                </a>
                <span className="dot-separator">•</span>
                <a href="https://instagram.com/myphotosgh" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="social-link"
                >
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                  <span>Instagram</span>
                </a>
              </div>
            </motion.div>

            <motion.div 
              className="contact-item"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="contact-icon">📍</span>
              <h4>Address</h4>
              <address className="contact-address">
                MyPhotos Gallery<br />
                East Legon<br />
                Accra, Ghana
              </address>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </div>
  );
}

export default Landing; 