import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PaymentForm from '../components/PaymentForm';
import { motion } from 'framer-motion';
import axios from 'axios';

function Renew() {
  const { subdomain } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const token = await user.getIdToken(true);
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        setGallery(response.data);
      } catch (err) {
        console.error('Error fetching gallery:', err);
        setError('Unable to load gallery details');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchGallery();
    }
  }, [user, subdomain]);

  const handlePaymentComplete = async (paymentData) => {
    try {
      // Update the subscription status
      const token = await user.getIdToken(true);
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}/renew`,
        { paymentData },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Redirect to profile with success message
      navigate('/profile', { 
        state: { message: 'Subscription renewed successfully!' }
      });
    } catch (err) {
      console.error('Error updating subscription:', err);
      setError('Failed to update subscription. Please try again.');
    }
  };

  if (!user) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="min-h-screen flex items-center justify-center bg-gray-50"
      >
        <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Sign In Required</h2>
          <p className="text-gray-600 mb-6">
            Please sign in to renew your gallery subscription.
          </p>
          <button
            onClick={() => navigate('/')}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
          >
            Go to Sign In
          </button>
        </div>
      </motion.div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate('/profile')}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
          >
            Back to Profile
          </button>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gray-50 py-8"
    >
      <div className="max-w-3xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Renew Subscription
          </h1>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">
              Gallery Details
            </h2>
            <div className="bg-gray-50 p-4 rounded-md">
              <p><span className="font-medium">Title:</span> {gallery?.title}</p>
              <p><span className="font-medium">Type:</span> {gallery?.event_type}</p>
              <p><span className="font-medium">URL:</span> {gallery?.subdomain}.myphotosgallery.com</p>
            </div>
          </div>
          
          <PaymentForm onPaymentComplete={handlePaymentComplete} />
        </div>
      </div>
    </motion.div>
  );
}

export default Renew; 