import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Landing from './pages/Landing';
import Setup from './pages/Setup';
import GalleryRouter from './components/GalleryRouter';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Profile from './pages/Profile';
import Renew from './pages/Renew';
import CustomizeGallery from './pages/CustomizeGallery';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { Register } from './pages/Register';
import { AffiliateDashboardPage } from './pages/AffiliateDashboardPage';

function App() {
  const hostname = window.location.hostname;
  const isMainDomain = hostname === 'myphotosgallery.com' || hostname === 'www.myphotosgallery.com' || hostname === 'localhost';
  const subdomain = hostname.split('.')[0];

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/register" element={<Register />} />
          {isMainDomain ? (
            // Main domain routes
            <>
              <Route path="/" element={<Landing />} />
              <Route path="/setup" element={<Setup />} />
              <Route 
                path="/admin" 
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                } 
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/renew/:subdomain" element={<Renew />} />
              <Route path="/customize/:subdomain" element={<CustomizeGallery />} />
              <Route path="/:subdomain" element={<GalleryRouter />} />
            </>
          ) : (
            // Subdomain route - always show gallery
            <Route path="*" element={<GalleryRouter />} />
          )}
          <Route path="/affiliate-dashboard" element={<AffiliateDashboardPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
