import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import './GallerySuccess.css';
import { getShareLinks } from '../components/ShareLinks';

function GallerySuccess({ subdomain }) {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const params = useParams();
  
  // Get subdomain from props or params
  const effectiveSubdomain = subdomain || params.subdomain;
  
  // Use the actual domain from the current URL
  const hostname = window.location.hostname;
  const domain = hostname === 'localhost' 
    ? 'myphotosgallery.com'  // Default domain for local development
    : hostname.includes('myphotosgallery.com') 
      ? 'myphotosgallery.com'  // Main domain
      : hostname;  // Fallback to full hostname

  const galleryUrl = `https://${effectiveSubdomain}.${domain}`;

  //const tempGalleryUrl = `https://${domain}/${effectiveSubdomain}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(galleryUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Update share links to use the new helper
  const shareLinks = getShareLinks(
    galleryUrl,
    `${effectiveSubdomain}'s Gallery`,
    `Check out ${effectiveSubdomain}'s photo gallery!`
  );

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-lg mx-auto">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Ultra-compact Success Header */}
            <div className="flex justify-center mt-10">
            <div className="bg-green-50 px-4 py-2 border border-green-100 rounded-lg max-w-md w-full">
                <div className="flex items-center gap-2">
                <div className="h-6 w-6 flex items-center justify-center rounded-full bg-green-100">
                    <svg className="h-4 w-4 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                </div>
                <h1 className="text-base font-medium text-green-900">
                    Gallery Created Successfully!
                </h1>
                </div>
            </div>
            </div>

          {/* Gallery Details - Reduced spacing */}
          <div className="px-4 py-3 space-y-3">
            <h2 className="text-sm font-medium text-gray-900">
              {/* galleryInfo.title will be undefined if galleryInfo is not provided */}
              {/* galleryInfo.title will be undefined if galleryInfo is not provided */}
            </h2>
            
            {/* Copy URL Section - More compact */}
            <div>
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Gallery URL
              </label>
              <div className="flex shadow-sm">
                <input
                  type="text"
                  readOnly
                  value={`${effectiveSubdomain}.${domain}`}
                  //value={galleryUrl}
                  className="flex-1 px-2 py-1.5 text-sm border border-r-0 border-gray-300 rounded-l-lg bg-gray-50"
                />
                <button
                  onClick={handleCopy}
                  className={`px-3 py-1.5 text-sm font-medium text-white rounded-r-lg transition-all duration-200 ${
                    copied ? 'bg-green-500' : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>

            {/* Share Options - Updated for better mobile display */}
            <div>
              <h3 className="text-xs font-medium text-gray-900 mb-1.5">Share your gallery</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">  {/* Changed to 2 columns on mobile */}
                {Object.entries(shareLinks).map(([platform, url]) => (
                  <a
                    key={platform}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex items-center justify-center gap-2 p-2 text-white rounded-lg transition-colors ${
                      platform === 'whatsapp' ? 'bg-green-500 hover:bg-green-600' :
                      platform === 'facebook' ? 'bg-blue-600 hover:bg-blue-700' :
                      platform === 'twitter' ? 'bg-blue-400 hover:bg-blue-500' :
                      platform === 'email' ? 'bg-gray-600 hover:bg-gray-700' :
                      'bg-gray-500 hover:bg-gray-600'
                    }`}
                  >
                    <ShareIcon platform={platform} />
                    <span className="text-sm whitespace-nowrap">  {/* Increased text size */}
                      {platform.charAt(0).toUpperCase() + platform.slice(1)}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Action Buttons - Compact footer */}
          <div className="bg-gray-50 px-4 py-2 flex justify-end gap-2 border-t border-gray-100">
            <Link
              to={`/${effectiveSubdomain}`}
              className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
            >
              View Gallery
            </Link>
            <Link
              to="/profile"
              className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Go to Profile
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper component for share icons
const ShareIcon = ({ platform }) => {
  const icons = {
    whatsapp: (
      <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771z"/>
      </svg>
    ),
    facebook: (
      <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
        <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/>
      </svg>
    ),
    twitter: (
      <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
        <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.58v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/>
      </svg>
    ),
    email: (
      <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
      </svg>
    ),
    sms: (
      <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
      </svg>
    ),
  };

  return icons[platform] || null;
};

export default GallerySuccess; 