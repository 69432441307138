export const logUserActivity = async (user, actionType, actionData = {}) => {
  if (!user) return;

  try {
    // Get the auth token from Firebase auth instance
    const auth = user.auth || window.firebase.auth();
    const currentUser = auth.currentUser;
    const token = await currentUser.getIdToken();

    await fetch(`${import.meta.env.VITE_API_URL}/api/analytics/log`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action_type: actionType,
        action_data: actionData
      })
    });
  } catch (error) {
    console.error('Failed to log activity:', error);
  }
}; 