import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getAuth } from 'firebase/auth';
import './Profile.css';
import { getShareLinks } from '../components/ShareLinks';
import { AffiliateRegistration } from '../components/AffiliateRegistration';
import { AffiliateDashboard } from '../components/AffiliateDashboard';

function Profile() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedGalleryId, setCopiedGalleryId] = useState(null);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [canBeAffiliate, setCanBeAffiliate] = useState(false);

  // Get the domain from the current URL, handling potential subdomain cases
  const hostname = window.location.hostname;
  const domain = hostname === 'localhost' 
    ? 'myphotosgallery.com'  // Default domain for local development
    : hostname.includes('myphotosgallery.com') 
      ? 'myphotosgallery.com'  // Main domain
      : hostname;  // Fallback to full hostname

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        
        // Fetch galleries
        const galleriesResponse = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/users/galleries`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        setGalleries(galleriesResponse.data);

        // Updated affiliate status check
        const affiliateResponse = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/affiliates/status`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        setIsAffiliate(affiliateResponse.data.isAffiliate);
        setCanBeAffiliate(affiliateResponse.data.canBeAffiliate);

      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response?.status === 401) {
          setError('Session expired. Please sign in again.');
        } else {
          setError('Unable to load data. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const handleCopy = async (url, galleryId) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedGalleryId(galleryId);
      setTimeout(() => setCopiedGalleryId(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getGalleryShareLinks = (gallery) => {
    const galleryUrl = `https://${gallery.subdomain}.${domain}`;
    return getShareLinks(
      galleryUrl,
      `${gallery.title}`,
      `Check out ${gallery.title}!`
    );
  };

  // Add ShareIcon component from GallerySuccess
  const ShareIcon = ({ platform }) => {
    const icons = {
      whatsapp: (
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771z"/>
        </svg>
      ),
      facebook: (
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
          <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/>
        </svg>
      ),
      twitter: (
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
          <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.58v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/>
        </svg>
      ),
      email: (
        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
        </svg>
      ),
      sms: (
        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
        </svg>
      ),
    };
    return icons[platform] || null;
  };

  if (!user) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="min-h-screen flex items-center justify-center profile-page"
      >
        <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-md w-full fade-in">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Sign In Required</h2>
          <p className="text-gray-600 mb-6">
            Please sign in to view your profile and manage your galleries.
          </p>
          <button
            onClick={() => navigate('/')}
            className="create-button text-white px-6 py-2 rounded-md"
          >
            Go to Sign In
          </button>
        </div>
      </motion.div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center profile-page">
        <div className="text-center fade-in">
          <div className="loading-spinner"></div>
          <p className="mt-4 text-gray-600">Loading your galleries...</p>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="profile-page py-8"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Profile Header */}
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="profile-header p-6 mb-8"
        >
          <div className="flex items-center space-x-4">
            <img
              src={user?.photoURL || 'https://via.placeholder.com/100'}
              alt="Profile"
              className="profile-image h-16 w-16 rounded-full"
            />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{user?.displayName}</h1>
              <p className="text-gray-600">{user?.email}</p>
            </div>
          </div>
        </motion.div>

        {/* Galleries Section */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">My Galleries</h2>
            <button
              onClick={() => navigate('/setup')}
              className="create-button px-4 py-2 rounded-md flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Create New Gallery
            </button>
          </div>

          {error ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="error-message"
            >
              {error}
            </motion.div>
          ) : galleries.length === 0 ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="empty-state"
            >
              <svg className="mx-auto h-12 w-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">No galleries yet</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by creating your first gallery</p>
            </motion.div>
          ) : (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="gallery-grid"
            >
              {galleries.map((gallery) => (
                <motion.div
                  key={gallery.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  whileHover={{ y: -5 }}
                  className="gallery-card"
                >
                  <div className="gallery-header">
                    <h3 className="text-xl font-semibold">{gallery.title}</h3>
                  </div>
                  <div className="gallery-content">
                    <div className="detail-row">
                      <span className="detail-label">Type:</span>
                      <span className="detail-value">{gallery.event_type}</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Date:</span>
                      <span className="detail-value">
                        {new Date(gallery.event_date).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Venue:</span>
                      <span className="detail-value">{gallery.venue}</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">URL:</span>
                      <a
                        href={`https://${gallery.subdomain}.myphotosgallery.com`}
                        //href={`https://myphotosgallery.com/${gallery.subdomain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gallery-link"
                      >
                        {gallery.subdomain}.myphotosgallery.com
                      </a>
                    </div>

                    {/* Add Share Section */}
                    <div className="mt-4 pt-4 border-t">
                      <h4 className="text-sm font-medium text-gray-900 mb-2">Share Gallery</h4>
                      
                      {/* Copy URL Section */}
                      <div className="mb-3">
                        <div className="flex shadow-sm">
                          <input
                            type="text"
                            readOnly
                            value={`https://${gallery.subdomain}.${domain}`}
                            className="flex-1 px-2 py-1.5 text-sm border border-r-0 border-gray-300 rounded-l-lg bg-gray-50"
                          />
                          <button
                            onClick={() => handleCopy(`https://${gallery.subdomain}.${domain}`, gallery.id)}
                            className={`px-3 py-1.5 text-sm font-medium text-white rounded-r-lg transition-all duration-200 ${
                              copiedGalleryId === gallery.id ? 'bg-green-500' : 'bg-blue-600 hover:bg-blue-700'
                            }`}
                          >
                            {copiedGalleryId === gallery.id ? 'Copied!' : 'Copy'}
                          </button>
                        </div>
                      </div>

                      {/* Share Options Grid - Updated for better mobile display */}
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        {Object.entries(getGalleryShareLinks(gallery)).map(([platform, url]) => (
                          <a
                            key={platform}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`flex items-center justify-center gap-2 p-2 text-white rounded-lg transition-colors ${
                              platform === 'whatsapp' ? 'bg-green-500 hover:bg-green-600' :
                              platform === 'facebook' ? 'bg-blue-600 hover:bg-blue-700' :
                              platform === 'twitter' ? 'bg-blue-400 hover:bg-blue-500' :
                              platform === 'email' ? 'bg-gray-600 hover:bg-gray-700' :
                              'bg-gray-500 hover:bg-gray-600'
                            }`}
                          >
                            <ShareIcon platform={platform} />
                            <span className="text-sm whitespace-nowrap">
                              {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </span>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Existing subscription details and buttons */}
                  <div className="mt-4 pt-4 border-t">
                    <h4 className="font-medium mb-2">Subscription Details</h4>
                    <div className="space-y-1">
                      <div className="detail-row">
                        <span className="detail-label">Status:</span>
                        <span className={`status-badge ${gallery.subscription_status === 'active' ? 'active' : 'inactive'}`}>
                          {gallery.subscription_status}
                        </span>
                      </div>
                      {gallery.subscription_start && (
                        <div className="detail-row">
                          <span className="detail-label">Started:</span>
                          <span className="detail-value">
                            {new Date(gallery.subscription_start).toLocaleDateString()}
                          </span>
                        </div>
                      )}
                      {gallery.subscription_end && (
                        <div className="detail-row">
                          <span className="detail-label">Expires:</span>
                          <span className="detail-value">
                            {new Date(gallery.subscription_end).toLocaleDateString()}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex gap-2 mt-4">
                      {gallery.subscription_status !== 'active' && (
                        <button
                          onClick={() => navigate(`/renew/${gallery.subdomain}`)}
                          className="renew-button flex-1 text-white py-2 px-4 rounded-md"
                        >
                          Renew Subscription
                        </button>
                      )}
                      <button
                        onClick={() => navigate(`/customize/${gallery.subdomain}`)}
                        className="edit-button flex-1 text-white py-2 px-4 rounded-md"
                      >
                        Customize Gallery
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>

        {/* Affiliate Section */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Affiliate Program</h2>
          </div>

          {isAffiliate ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="gallery-card"
            >
              <div className="gallery-header">
                <h3 className="text-xl font-semibold">Your Affiliate Status</h3>
              </div>
              <div className="gallery-content">
                <p className="text-gray-600 mb-4">
                  You are registered as an affiliate. View your dashboard to manage your referrals and earnings.
                </p>
                <button
                  onClick={() => navigate('/affiliate-dashboard')}
                  className="create-button px-4 py-2 rounded-md flex items-center"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  View Affiliate Dashboard
                </button>
              </div>
            </motion.div>
          ) : canBeAffiliate ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="gallery-card"
            >
              <AffiliateRegistration onSuccess={() => setIsAffiliate(true)} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="gallery-card"
            >
              <div className="gallery-content">
                <p className="text-gray-600">
                  The affiliate program is currently by invitation only. Please contact support if you're interested in becoming an affiliate.
                </p>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Profile; 