// New shared component for consistent sharing functionality
export const getShareLinks = (url, title = 'Photo Gallery', description = 'Check out my photo gallery!') => {
  // Encode the URL with our Open Graph meta tags
  const shareUrl = `${url}?utm_source=share`;
  
  return {
    whatsapp: `https://wa.me/?text=${encodeURIComponent(`${description} ${shareUrl}`)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(description)}`,
    email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`${description} ${shareUrl}`)}`,
    sms: `sms:?&body=${encodeURIComponent(`${description} ${shareUrl}`)}`
  };
}; 