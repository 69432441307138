import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './CustomizeGallery.css';
import { GalleryThemeProvider } from '../components/GalleryThemeProvider';
import { getAuth } from 'firebase/auth';
import EmojiPicker from 'emoji-picker-react';

// Import gallery components
import Gallery from '../pages/Gallery';
import PartyGallery from '../pages/PartyGallery';
import GraduationGallery from '../pages/GraduationGallery';
import BirthdayGallery from '../pages/BirthdayGallery';

function CustomizeGallery() {
  const { subdomain } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [previewKey, setPreviewKey] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  
  // Theme state with default values
  const [customization, setCustomization] = useState({
    title: '',
    date: '',
    place: '',
    tagline: '',
    divider: '•',
    fontFamily: 'Inter',
    // Commented out for later implementation
    // backgroundColor: '#f6f9fc',
    // headerColor: '#1a1a1a',
    // textColor: '#4a4a4a',
    // accentColor: '#3b82f6',
    // cardBackground: '#ffffff',
  });

  useEffect(() => {
    const fetchGallery = async () => {
      if (!user || !subdomain) return;

      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        
        // Fetch both gallery data and customization data
        const [galleryResponse, customizeResponse] = await Promise.all([
          axios.get(
            `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          ),
          axios.get(
            `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}/customize`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          )
        ]);

        if (!galleryResponse.data) {
          setError('Gallery not found');
          setLoading(false);
          return;
        }

        // Then fetch the photos
        const photosResponse = await axios.get(
          `${import.meta.env.VITE_API_URL}/api/albums/${galleryResponse.data.google_album_id}/photos`
        );

        // Combine gallery data with photos
        setGallery({
          ...galleryResponse.data,
          photos: photosResponse.data,
          customization: customizeResponse.data
        });

        // Set customization from the customize endpoint if it exists
        if (customizeResponse.data) {
          setCustomization(prev => ({
            ...prev, // Keep defaults as fallback
            title: customizeResponse.data.title || galleryResponse.data.title || '',
            date: customizeResponse.data.date || galleryResponse.data.event_date || '',
            place: customizeResponse.data.place || galleryResponse.data.venue || '',
            tagline: customizeResponse.data.tagline || galleryResponse.data.tagline || '',
            divider: customizeResponse.data.divider || galleryResponse.data.divider_emoji || '•',
            fontFamily: customizeResponse.data.fontFamily || 'Inter'
          }));
        }

      } catch (err) {
        console.error('Error fetching gallery:', err);
        setError(err.response?.data?.error || 'Failed to load gallery');
      } finally {
        setLoading(false);
      }
    };

    if (user && subdomain) fetchGallery();
  }, [user, subdomain]);

  const handleCustomizationChange = (key, value) => {
    setCustomization(prev => ({
      ...prev,
      [key]: value
    }));
    // Force preview to update
    setPreviewKey(prevKey => prevKey + 1);
  };

  const saveCustomization = async () => {
    if (!subdomain) {
      setError('No gallery specified');
      return;
    }

    setSaving(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      
      // Update the gallery details to match the database schema
      const updateData = {
        // Gallery details (matching galleries table columns)
        title: customization.title,
        event_date: customization.date,    // matches event_date in schema
        venue: customization.place,        // matches venue in schema
        tagline: customization.tagline,    // matches tagline in schema
        divider_emoji: customization.divider, // matches divider_emoji in schema
        // Theme customization (matching JSONB customization column structure)
        customization: {
          // Include gallery details in customization for theme
          title: customization.title,
          date: customization.date,
          place: customization.place,
          tagline: customization.tagline,
          divider: customization.divider,
          // Theme properties
          fontFamily: customization.fontFamily,
          backgroundColor: null,
          headerColor: null,
          textColor: null,
          accentColor: null,
          cardBackground: null
        }
      };

      await axios.put(
        `${import.meta.env.VITE_API_URL}/api/galleries/${subdomain}/customize`,
        updateData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      navigate('/profile', { 
        state: { message: 'Gallery customization saved successfully!' }
      });
    } catch (err) {
      console.error('Error saving customization:', err);
      setError(err.response?.data?.error || 'Failed to save customization');
    } finally {
      setSaving(false);
    }
  };

  const renderGalleryPreview = () => {
    if (!gallery) return null;

    const props = {
      previewMode: true,
      gallery: {
        ...gallery,
        customization: customization // Pass the current theme
      },
      photos: gallery.photos
    };

    // Return the gallery component with theme
    switch (gallery.event_type?.toLowerCase()) {
      case 'wedding':
        return <Gallery key={previewKey} {...props} />;
      case 'party':
        return <PartyGallery key={previewKey} {...props} />;
      case 'graduation':
        return <GraduationGallery key={previewKey} {...props} />;
      case 'birthday':
        return <BirthdayGallery key={previewKey} {...props} />;
      case 'photoshoot':
        return <PartyGallery key={previewKey} {...props} />;
      default:
        return <div>Unknown gallery type</div>;
    }
  };

  // Add emoji handler
  const handleEmojiSelect = (emojiData) => {
    handleCustomizationChange('divider', emojiData.emoji);
    setShowEmojiPicker(false);
  };

  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error-screen">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/profile')}>Back to Profile</button>
      </div>
    );
  }

  if (!gallery) {
    return (
      <div className="error-screen">
        <h2>Gallery Not Found</h2>
        <p>The requested gallery could not be found.</p>
        <button onClick={() => navigate('/profile')}>Back to Profile</button>
      </div>
    );
  }

  return (
    <div className="customize-page">
      <div className="customize-container">
        <div className="customize-panel">
          <div className="customize-panel-content">
            <h2 className="customize-title">Customize Gallery</h2>
            
            <div className="customize-sections">
              <div className="text-content-section">
                <h3>Gallery Details</h3>
                
                <div className="input-group">
                  <label>Title</label>
                  <input
                    type="text"
                    value={customization.title}
                    onChange={(e) => handleCustomizationChange('title', e.target.value)}
                    placeholder="Enter gallery title"
                    className="customize-input"
                  />
                </div>

                <div className="input-group">
                  <label>Date</label>
                  <input
                    type="date"
                    value={customization.date}
                    onChange={(e) => handleCustomizationChange('date', e.target.value)}
                    className="customize-input"
                    required
                  />
                </div>

                <div className="input-group">
                  <label>Place</label>
                  <input
                    type="text"
                    value={customization.place}
                    onChange={(e) => handleCustomizationChange('place', e.target.value)}
                    placeholder="Enter event location"
                    className="customize-input"
                  />
                </div>

                <div className="input-group">
                  <label>Tagline</label>
                  <input
                    type="text"
                    value={customization.tagline}
                    onChange={(e) => handleCustomizationChange('tagline', e.target.value)}
                    placeholder="Enter gallery tagline"
                    className="customize-input"
                  />
                </div>

                <div className="input-group">
                  <label>Divider Symbol</label>
                  <div className="emoji-selector">
                    <button 
                      type="button" 
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className="emoji-button"
                    >
                      {customization.divider || '•'}
                    </button>
                    {showEmojiPicker && (
                      <div className="emoji-picker-container">
                        <EmojiPicker onEmojiClick={handleEmojiSelect} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="font-section">
                <h3>Font Style</h3>
                <select
                  value={customization.fontFamily}
                  onChange={(e) => handleCustomizationChange('fontFamily', e.target.value)}
                  className="customize-select"
                >
                  <option value="Inter">Inter</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Playfair Display">Playfair Display</option>
                  <option value="Montserrat">Montserrat</option>
                </select>
              </div>

              {/* Commented out for later implementation
              <div className="color-section">
                <h3>Colors</h3>
                ... color customization options ...
              </div>
              */}
            </div>

            <div className="button-group">
              <button 
                className="save-button"
                onClick={saveCustomization}
                disabled={saving}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </button>
              <button 
                className="cancel-button"
                onClick={() => navigate('/profile')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div className="preview-panel">
          <div className="preview-header">
            <h3>Live Preview</h3>
            {gallery && <span className="gallery-type">{gallery.event_type} Gallery</span>}
          </div>
          <div className="preview-container">
            <GalleryThemeProvider theme={customization}>
              {renderGalleryPreview()}
            </GalleryThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomizeGallery; 