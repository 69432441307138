import { useNavigate } from 'react-router-dom';
import { AffiliateDashboard } from '../components/AffiliateDashboard';
import { motion } from 'framer-motion';

export const AffiliateDashboardPage = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gray-50 py-8"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Affiliate Dashboard</h1>
          <div className="space-x-4">
            <button
              onClick={() => navigate('/profile')}
              className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-md border border-gray-300 hover:border-gray-400 transition-colors"
            >
              Back to Profile
            </button>
            <button
              onClick={() => navigate('/')}
              className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-md border border-gray-300 hover:border-gray-400 transition-colors"
            >
              Home
            </button>
          </div>
        </div>

        {/* Dashboard Content */}
        <AffiliateDashboard />
      </div>
    </motion.div>
  );
}; 