import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

export const AffiliateRegistration = () => {
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    momoNumber: '',
    momoName: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const token = await auth.currentUser.getIdToken();
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/affiliates/register`,
        { paymentDetails },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSuccess(true);
    } catch (error) {
      console.error('Error registering as affiliate:', error);
      setError(error.response?.data?.error || 'Failed to register as affiliate');
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <div className="bg-gradient-to-br from-green-50 to-green-100 p-6 rounded-lg border border-green-200">
        <div className="flex items-center justify-center mb-4">
          <div className="bg-green-100 rounded-full p-2">
            <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
        <h3 className="text-xl text-center text-green-800 font-semibold">Welcome to Our Affiliate Program!</h3>
        <p className="text-green-700 text-center mt-3">
          You're now part of our team. Head to your affiliate dashboard to start earning!
        </p>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-white to-blue-50 p-8 rounded-xl shadow-lg border border-blue-100">
      <div className="flex items-center justify-center mb-6">
        <div className="bg-blue-100 rounded-full p-3">
          <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Join Our Affiliate Program</h2>
        <p className="text-gray-600">
          Partner with us and earn 10% commission on every subscription
        </p>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-4 w-4 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="ml-2">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Mobile Money Number
          </label>
          <input
            type="text"
            value={paymentDetails.momoNumber}
            onChange={(e) => setPaymentDetails(prev => ({
              ...prev,
              momoNumber: e.target.value
            }))}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            placeholder="Enter your MoMo number"
            required
          />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Mobile Money Name
          </label>
          <input
            type="text"
            value={paymentDetails.momoName}
            onChange={(e) => setPaymentDetails(prev => ({
              ...prev,
              momoName: e.target.value
            }))}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            placeholder="Name registered with MoMo"
            required
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full py-3 px-4 rounded-lg font-medium text-white transition-all duration-200
            ${isLoading 
              ? 'bg-blue-400 cursor-not-allowed' 
              : 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 shadow-md hover:shadow-lg'}`}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              Registering...
            </div>
          ) : 'Join Affiliate Program'}
        </button>
      </form>

      <div className="mt-8 bg-white p-6 rounded-lg shadow-sm">
        <h4 className="font-semibold text-gray-900 mb-4">How it works</h4>
        <div className="space-y-4">
          {[
            { 
              text: "Register with your payment details",
              icon: " " // Simple pound symbol
            },
            { 
              text: "Get your unique referral link",
              icon: "🔗 " // Link symbol
            },
            { 
              text: "Share with photographers",
              icon: "👥 " // People symbol
            },
            { 
              text: "Earn 10% commission on subscriptions",
              icon: "% " // Percentage symbol
            },
            { 
              text: "Get paid via Mobile Money",
              icon: "💰 " // Money bag symbol
            }
          ].map((item, index) => (
            <div key={index} className="flex items-center text-gray-600">
              <span className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 text-gray-800 text-sm mr-3">
                {item.icon}
              </span>
              <span className="text-sm">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}; 